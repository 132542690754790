import { WrapRootElementBrowserArgs, WrapRootElementNodeArgs } from 'gatsby'
import React from 'react'
import { Provider } from 'react-redux'
import { createStore, applyMiddleware, compose } from 'redux'
import immutableStateInvariantMiddleware from 'redux-immutable-state-invariant'
import thunk from 'redux-thunk'

import rootReducer from './reducers'

const initialState = {}
const middleware = [immutableStateInvariantMiddleware(), thunk]

const store = createStore(rootReducer, initialState, compose(applyMiddleware(...middleware)))

function WrapperStore({ element }: WrapRootElementBrowserArgs | WrapRootElementNodeArgs) {
  return <Provider store={store}>{element}</Provider>
}
export default WrapperStore
