import { ReduxFormState } from '@redux/actions/ValidationActions'

import { Country, Language, Locale } from '@typings/Localization'
import { Reservation } from '@typings/Reservation'
import { CheckoutValidations } from '@typings/ValidationResponse'

import { decrypt, encrypt } from './encryptToken'

type StoredFormData = {
  form: ReduxFormState
  currentStep?: number
  reservation: Reservation
  validations?: CheckoutValidations
  raSolvableMessageCode?: string
}

export const removeFormState = () => {
  if (localStorage.getItem('storedFormState')) {
    localStorage.removeItem('storedFormState')
  }
}

export const saveFormState = (state: StoredFormData) => {
  localStorage.setItem('storedFormState', encrypt(JSON.stringify(state)))
}

export const getFormState = () => {
  const localStorageData = localStorage.getItem('storedFormState')

  if (localStorageData) {
    try {
      const data: StoredFormData = JSON.parse(decrypt(localStorageData))
      return data
    } catch (error) {
      removeFormState()
      return false
    }
  }
  return false
}

export const getLocaleFromLocalstorage = () => {
  if (typeof window !== 'undefined') {
    const storedLocale = localStorage.getItem('locale')
    if (storedLocale) {
      const locale: Locale = {
        language: storedLocale?.split('-')[0] as Language,
        country: storedLocale?.split('-')[1] as Country,
      }
      return locale
    }
  }
}
