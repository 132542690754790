import { Brand, ThemeProvider } from '@hertz/odinui-web'
import type { GatsbyBrowser } from 'gatsby'
import { PrismicPreviewProvider } from 'gatsby-plugin-prismic-previews'
import * as React from 'react'
import { HelmetProvider, Helmet } from 'react-helmet-async'

import WrapperStore from './src/redux/WrapperStore'
import getBrandFavicon from './src/utils/getBrandFavicon'

import './src/style/Global.css'

export const wrapRootElement: GatsbyBrowser['wrapRootElement'] = (props) => {
  const brandFromStorage = ((typeof window !== 'undefined' && localStorage.getItem('brand')) as Brand) || 'hertz'

  return (
    <PrismicPreviewProvider>
      <ThemeProvider brand={brandFromStorage}>
        <HelmetProvider>
          <Helmet>
            <title>Hertz Self Checkout</title>
            <link rel="icon" href={getBrandFavicon(brandFromStorage)} />
            <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0" />
          </Helmet>
          <WrapperStore
            element={props.element}
            getResourceURLsForPathname={props.getResourceURLsForPathname}
            pathname={props.pathname}
          />
        </HelmetProvider>
      </ThemeProvider>
    </PrismicPreviewProvider>
  )
}
