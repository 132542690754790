import * as JsCrypto from 'jscrypto/es6'

/**
 * Encrypts the token using the `AES` standard and `GCM` mode
 * @param token the string to encrypt
 * @returns the encrypted token in string format
 */
export default function encryptToken(token: string) {
  const mode = JsCrypto.mode.GCM
  const padding = JsCrypto.pad.NoPadding

  // create a hex out of the token string
  const tokenInHex = token
    .split('')
    .map((c) => c.charCodeAt(0).toString(16).padStart(2, '0'))
    .join('')

  // Format it into a JsCrypto format (wordArray)
  const message = JsCrypto.Hex.parse(tokenInHex)
  const key = JsCrypto.Hex.parse(process.env.GATSBY_AES_KEY as string) // our key in hex format
  const iv = JsCrypto.Word32Array.random(12) // the iv is randomly generated each time
  const aad = JsCrypto.Utf8.parse('')

  const cipher = JsCrypto.AES.encrypt(message, key, {
    mode,
    iv,
    padding,
  })

  // Tag needed by the java BE to decrypt, length of (16) by default
  const tag = JsCrypto.mode.GCM.mac(JsCrypto.AES, key, iv, aad, cipher.cipherText)

  let cipherText = ''
  if (cipher.cipherText) {
    cipherText = `${iv.toString()}${cipher.cipherText.toString()}${tag.toString()}`
  }
  return cipherText
}

export const encrypt = (text: string) => {
  const key = JsCrypto.Hex.parse(process.env.GATSBY_AES_KEY as string)
  return JsCrypto.AES.encrypt(text, key).toString()
}

export const decrypt = (text: string) => {
  const key = JsCrypto.Hex.parse(process.env.GATSBY_AES_KEY as string)
  return JsCrypto.AES.decrypt(text, key).toString(JsCrypto.Utf8)
}
