export const RESERVATIONACTION = {
  ADD_RESERVATION: 'ADD_RESERVATION',
  UPDATE_RESERVATION: 'UPDATE_RESERVATION',
  ERROR_RESERVATION: 'ERROR_RESERVATION',
}

export const LANGUAGEACTION = {
  SET_LOCALE: 'SET_LOCALE',
}

export const VALIDATIONACTION = {
  FETCH_VALIDATIONS: 'FETCH_VALIDATIONS',
  SAVE_FORM: 'SAVE_FORM',
  INITIATED_PAYMENT: 'INITIATED_PAYMENT',
  SET_RENTAL_AGREEMENT_SOLVABLE: 'SET_RENTAL_AGREEMENT_SOLVABLE',
}

export const PRECHECKOUTACTION = {
  SAVE_STEP: 'SAVE_STEP',
  UPDATE_INSURANCES: 'UPDATE_INSURANCES',
}
