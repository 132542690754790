import { PRECHECKOUTACTION } from '@constants/ActionTypes'
import { EditableInsurances } from '@typings/EditableInsurances'
import PreCheckoutStep from '@typings/PreCheckoutStep'

export type PreCheckoutReducerProps = {
  type: string
  currentStep: PreCheckoutStep
  insurances: EditableInsurances | null
}

const initialState: Omit<PreCheckoutReducerProps, 'type'> = {
  currentStep: PreCheckoutStep.insurances,
  insurances: null,
}

const PreCheckoutReducer = (state = initialState, action: PreCheckoutReducerProps) => {
  switch (action.type) {
    case PRECHECKOUTACTION.SAVE_STEP:
      return {
        ...state,
        currentStep: action.currentStep,
      }
    case PRECHECKOUTACTION.UPDATE_INSURANCES:
      return {
        ...state,
        insurances: action.insurances,
      }
    default:
      return state
  }
}
export default PreCheckoutReducer
