import { VALIDATIONACTION } from '@constants/ActionTypes'
import { CheckoutValidations } from '@typings/ValidationResponse'

import { CreditCardInfo, ReduxFormState, ValidationReservationProps } from '../actions/ValidationActions'

export type ValidationReducerProps = {
  validations?: CheckoutValidations
  storedFormState?: ReduxFormState
  creditCardInfo?: CreditCardInfo
  raSolvableMessageCode?: string
  currentStep: number
}

const initialState: ValidationReducerProps = { currentStep: 0 }

const ValidationReducer = (
  state = initialState,
  action: ValidationReservationProps & { currentStep: number }
): ValidationReducerProps => {
  switch (action.type) {
    case VALIDATIONACTION.FETCH_VALIDATIONS:
      return {
        validations: action.validations,
        raSolvableMessageCode: action.raSolvableMessageCode,
        currentStep: 0,
      }
    case VALIDATIONACTION.SAVE_FORM:
      return {
        ...state,
        storedFormState: action.storedFormState,
        currentStep: action.currentStep,
      }
    case VALIDATIONACTION.INITIATED_PAYMENT:
      return {
        ...state,
        creditCardInfo: action.creditCardInfo,
      }
    case VALIDATIONACTION.SET_RENTAL_AGREEMENT_SOLVABLE:
      return {
        raSolvableMessageCode: action.raSolvableMessageCode,
        currentStep: 0,
      }
    default:
      return state
  }
}
export default ValidationReducer
