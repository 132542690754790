import { combineReducers } from 'redux'

import languageReducer from './LanguageReducer'
import preCheckoutReducer from './PreCheckoutReducer'
import reservationReducer from './ReservationReducer'
import validationReducer from './ValidationReducer'

export default combineReducers({
  reservationReducer,
  languageReducer,
  validationReducer,
  preCheckoutReducer,
})
