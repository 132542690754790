import { LANGUAGEACTION } from '@constants/ActionTypes'
import { Country, Language, Locale } from '@typings/Localization'

import { defaultLocale, locales } from '../../../prismic-configuration'

export type LanguageReducerProps = {
  isLoading: boolean
  locale: Locale
}

const mapLocale = (): Locale => {
  if (typeof window !== 'undefined') {
    const browserLanguage = navigator?.language.toLocaleLowerCase()
    const browserLocale = locales.find((locale) => locale.includes(browserLanguage))
    const locale = localStorage.getItem('locale') || browserLocale || defaultLocale

    return {
      language: (locale?.split('-')[0] as Language) || 'en',
      country: (locale?.split('-')[1] as Country) || 'us',
    }
  }
  return { language: defaultLocale.split('-')[0] as Language, country: defaultLocale.split('-')[1] as Country }
}

export const composeLocale = (locale: Locale) => {
  return `${locale.language || 'en'}-${locale.country || 'us'}`
}

const initialState: LanguageReducerProps = {
  isLoading: true,
  locale: mapLocale(),
}

const LanguageReducer = (state = initialState, action: { locale: Locale; type: string }) => {
  switch (action.type) {
    case LANGUAGEACTION.SET_LOCALE:
      typeof window !== 'undefined' && localStorage.setItem('locale', composeLocale(action.locale))
      return { ...state, isLoading: false, locale: action.locale }
    default:
      return state
  }
}
export default LanguageReducer
