import { RESERVATIONACTION } from '@constants/ActionTypes'
import { Brand } from '@hertz/odinui-web'

import { Reservation } from '@typings/Reservation'
import { removeFormState } from '@utils/localStorageService'

import { FetchReservationProps, UpdatedReservationStatus } from '../actions/ReservationActions'

export type ReservationReducerProps = {
  isLoading: boolean
  brand: Brand
  reservation?: Reservation
  updatedReservationStatus?: UpdatedReservationStatus
  errorMessage?: string
}

const initialState: ReservationReducerProps = {
  isLoading: true,
  brand: ((typeof window !== 'undefined' && localStorage.getItem('brand')) || 'hertz') as Brand,
}

const ReservationReducer = (state = initialState, action: FetchReservationProps) => {
  switch (action.type) {
    case RESERVATIONACTION.ADD_RESERVATION:
      removeFormState()
      const brand = (action.reservation?.brand.toLowerCase() || 'hertz') as Brand
      localStorage.setItem('brand', brand)
      return {
        reservation: action.reservation,
        isLoading: false,
        brand,
      }
    case RESERVATIONACTION.UPDATE_RESERVATION:
      return {
        isLoading: action.updatedReservationStatus?.failed,
        brand: state.brand,
        updatedReservationStatus: action.updatedReservationStatus,
      }
    case RESERVATIONACTION.ERROR_RESERVATION:
      return {
        ...state,
        isLoading: false,
        errorMessage: action.errorMessage,
      }
    default:
      return state
  }
}
export default ReservationReducer
