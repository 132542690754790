export const repositoryName = 'self-checkout'
export const releaseID = ''
export const defaultLocale = 'en-us'
export const locales = ['en-us', 'sv-se', 'en-se', 'no-no', 'en-no', 'da-dk', 'en-dk', 'fi-fi', 'en-fi']
export const timezones: { [key: string]: string } = {
  se: 'Europe/Stockholm',
  dk: 'Europe/Copenhagen',
  fi: 'Europe/Helsinki',
  no: 'Europe/Oslo',
  us: 'Europe/Stockholm',
}
